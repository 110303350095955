.post-container {
  padding: 16px;
}
.post-container img {
  cursor: zoom-in;
}
.post-title {
  margin-bottom: 12px;
}
.post-container blockquote {
  border-left: 5px solid #f1f1f1;
  margin: 16px 0;
  padding-left: 5px;
}
.post-container ul {
  margin: 16px 0;
  padding: 0;
}
.post-container li {
  margin: 16px 0 16px 16px;
}
.post-container pre {
  background: #f1f1f1;
  border-radius: 3px;
  padding: 16px 10px;
  overflow-x: auto;
}
.post-body img {
  margin: 16px 0;
}
.post-image-viewer {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: zoom-out;
}
.post-image-viewer img {
  width: auto;
  height: auto;
  max-width: 80vw;
  max-height: 80vh;
}
